body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    background: aliceblue;
    min-width: 1070px;
}

h1 {
    font-size: 24px;
    margin: 17px 0;
}

h2 {
    font-size: 23px;
    margin: 14px 0;
}

blockquote {
    border-left: 5px solid #7997af;
}

strong {
    font-weight: 600 !important;
}

lable {
    color: #585858 !important;
}

.preloader-center {
    width: 100%;
    text-align: center;
    padding: 15%;
}

.navigate {
    width: 100%;
    margin: 1px;
    background: white;
    border-radius: 4px;
    border: solid #b9d5fd 1px;
}

.navigate li {
    margin: 5px 0px 5px;
}

.navigate-item {
    cursor: pointer;
    width: 100%;
    margin-bottom: 1px;
    box-shadow: none;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: auto;
    text-align: center;
    line-height: 21px;
    padding: 8px 0;
}

.navigate-item:after {
    width: 17px;
}


.navigate-item a, .navigate-item span {
    margin: 15px 0;
}

.navigate-item span {
    width: 20px !important;
}

.navigate-item p {
    width: 40% !important;
}

.active-nav {
    background-color: #4c87af;
    color: white;
}

.de-active-nav {
    background-color: #e8e8e8;
    color: black;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
    background-color: #4c87af;
}


#headerDiv {
    display: grid;
    grid-template-areas: "header1 header2 header3 header4 header5";
    grid-template-columns: 2% 13% auto 13% 1%;
    grid-gap: 10px;
    height: 40px;
    background: white;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.32);
}

#topDiv {
    display: grid;
    grid-template-areas: "header1 header2 header3 header4 header5";
    grid-template-columns: 2% 13% auto 13% 1%;
    grid-gap: 10px;
}

#mainDiv {
    display: grid;

    grid-template-areas: "left1 left midle right right1";
    grid-template-columns: 2% 13% 69% 13% 3%;
    grid-gap: 10px;
    margin-top: 3px;
    min-width: 1000px;
}

#bottomDiv {
    display: grid;

    grid-template-areas: "footer1 footer2 footer3 footer4 footer5";
    grid-template-columns: 6% 12% 60% 16% 6%;
    grid-gap: 10px;

    background: black;
    background: linear-gradient(to top left, black, #022535);
    height: 60px;
}

#back {
    position: fixed;
    top: 0px;
    z-index: -1;
    background-image: url("/images/back.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
}

@media (max-width: 1304px) {
    #mainDiv {
        grid-template-columns: 0 17% 82% 1% 0;
        grid-gap: 2px;
    }

    #headerDiv {
        grid-template-columns: 0 1% 98% 1% 0;
        grid-gap: 2px;
    }

    #topDiv {
        grid-template-columns: 6% 10% 84% 0 0;
        grid-gap: 2px;
    }

    h1 {
        font-size: 20px !important;
    }

    .balanceTable {
        font-size: 12px;
    }

    .hr-type2 {
        width: 65px !important;
    }
}

.balanceTable {
    font-size: 14px;
}

.header-margin {
    margin-top: 6px;
    margin-bottom: -6px;
}

.topP {
    min-width: fit-content;
    margin-top: -3px;
}

.btnTop {
    height: 29px;
    line-height: 30px;
    margin-top: 5px;
    width: max-content;
}

.divBtnPay {
    float: right !important;
}

.btnPayImg {
    width: 37px;
    padding: 7px 7px 0;
    position: relative !important;
    top: 3px;
}

/*.btnPay {
    float: right;
    color: rgb(17, 162, 113);
    font-weight: 600;
    font-size: 22px;
    margin-top: 3px;
    text-shadow: 1px 1px 2px #fff;
    border: 1px solid rgba(85, 151, 49, 0);
    padding: 4px 5px 5px;
    height: 50px;
    min-width: 275px;
}*/
.btnPay {
    float: right;
    margin-top: 7px;
    height: 43px;
    line-height: 30px;
}
.btnPayPersonal {
    height: 31px;
    /*min-width: 410px;*/
}

.btnPay:hover {
    background-color: rgba(17,167,116,.9);
}
.pBtnPay{
    text-transform: initial;
    margin: 0;
    font-size: 12px;
    position: relative;
    top: -12px;
}
.btnInfo {
    height: 20px;
    line-height: 22px;
    width: max-content;
}

.siteName {
    font-size: 28px !important;
    font-weight: 600;
    color: #071739;
    text-shadow: 1px 1px 2px white;
    min-width: max-content;
}


.item-center {
    grid-area: midle;
    height: min-content;
    flex-wrap: wrap;
    min-height: 800px;
}

#content, .d_content {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #b9d5fd;
}

.item-left {
    grid-area: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    height: 20px;
}

.item-right {
    grid-area: right;
    height: min-content;
    display: flex;
}

.item-header2 {
    grid-area: header2;
}

.item-header3 {
    grid-area: header3;
    /*min-width: 930px;*/
}

.item-header4 {
    grid-area: header4;
    float: right;
}

.item-bottom2 {
    grid-area: footer2;
    background-color: black;
}

.item-bottom3 {
    grid-area: footer3;
}

.item-bottom4 {
    grid-area: footer4;
}


.backPlus {
    background-image: url(/images/icon/plus.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e8e8e8;
    width: 100%;
    box-shadow: none;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    float: right;
    margin-top: 4px;
    height: 30px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100px;
    opacity: 1;
}

.dropPos {
    top: 46px;
}

.dropbtn {
    display: inline-flex;
    cursor: pointer;
}

.dropbtn p {
    position: relative;
    top: -5px;
    left: 5px;
    width: 50px;
}

.dropbtn p::after {
    content: "▼";
    font-size: 12px;
    padding-left: 5px;
}


/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 2px 0 2px 5px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


.new_div {
    background-color: #fff;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
}

.new_div h3 {
    margin: 10px;
    font-size: 24px;
}

.new_txt_r {
    font-size: 17px;
    padding: 0px 10px;
}

.line-span {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0)); /* указываем угол  0deg для того чтобы градиент начинался от левого края, иначе градиент пойдёт сверху */
    background-repeat: no-repeat; /* убираем повторение градиента, необходимо для работы background-size */
    background-position: 50% 100%;
    background-size: 100% 1px;

    padding-bottom: 10px;
}


.page-footer {
    background: linear-gradient(to top, #022535, #012e42);
    padding: 0px;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.42);
}

.footer-copyright {
    background: linear-gradient(to top, black, #022535);
}

.row {
    margin: 0px;
}

.modal {
    max-width: 650px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.login-btn {
    min-width: 130px;
    margin: 6px 17px !important;
    text-align: center;
}


.flip-back {
    display: none;
}


.link-btn {
    position: absolute;
    margin-top: 35px;
    cursor: pointer;
}

.autocomplete-content {
    top: 47px !important;
    max-height: 200px;
}
/*
.circle {
    display: none !important;
}

.circlePreload {
    display: block !important;
}*/

.preloaderPos {
    left: 46%;
    top: 120px;
}

.scroll {
    overflow: auto;
}

.hr-type2 {
    width: 80px;
}

.print_btn_visible {
    background-image: url('/images/icon/printer.png');
    background-size: cover;
}

.print_btn {
    width: 38px !important;
    height: 38px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    float: right;
    margin: 9px 5px -29px;
}

.print_btn:hover {
    background-color: transparent !important;
}

.toast-Error {
    background-color: #bd0404 !important;
    font-weight: bold !important;
    max-width: 440px;
}

.unVis {
    display: none;
}

.pagination {
    margin: 20px auto 5px;
}

.pagination li {
    width: 27px;
    height: 27px;
    padding-top: 3px;
}

.pagination li.active {
    background-color: #4c87af;
    color: white;
}

.UNselect {
    display: block;
}

.tabs {
    overflow: hidden;
}

.tabs .indicator {
    background-color: #4c87af !important;
    height: 3px !important;
}

.tabs .tab {
    width: 50%;
}

.tabs .tab a {
    color: rgb(76, 135, 175);
}

.tabs .tab a:hover, .tabs .tab a.active {
    color: #4c87af;
    font-weight: 600;
}

